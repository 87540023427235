<template>
  <header class="main-header">
    <div :class="['main-header--wrapper', { 'flyout-open': isMobileNavbarVisible }]">
      <button
        :aria-expanded="!!isMobileNavbarVisible"
        aria-controls="primary-navigation"
        aria-labelledby="primary-navigation-label"
        class="main-header__burger-button"
        @click="isMobileNavbarVisible = !isMobileNavbarVisible"
      >
        <span id="primary-navigation-label" hidden>Hauptnavigation</span>
        <PflegeIcon aria-hidden="true" class="button-icon" color="blue" name="menu-line" size="l" />
      </button>
      <figure class="main-header__figure">
        <NuxtLink
          :to="useGetInternalLinkFromUrl(props.blok.headerLogo[0]?.headerLogoLink?.cached_url)"
          class="main-header__logo"
        >
          <NuxtImg
            :src="props.blok.headerLogo[0].src?.filename"
            :alt="props.blok.headerLogo[0].src?.alt"
            :title="props.blok.headerLogo[0].src?.title"
          />
        </NuxtLink>
      </figure>
      <nav class="main-header__nav">
        <ul class="main-header__list">
          <li v-for="item in props.blok.navLinks" :key="item._uid" class="main-header__list-item">
            <NuxtLink
              :target="item.link?.target"
              :title="item.linkTitel"
              :to="useCalcedLink(item)"
              class="main-header__list-link"
            >
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="item.linkLabel" />
            </NuxtLink>
          </li>

          <PflegeDivider
            v-if="isMobileNavbarVisible && props.blok.specialNavLinks.length > 0"
            borderColor="mint-dark"
            class="main-header__list--divider"
            height="2rem"
          />

          <li
            v-for="item in props.blok.specialNavLinks"
            :key="item?._uid"
            class="main-header__list-item"
          >
            <NuxtLink
              v-if="typeof item !== 'undefined'"
              :target="item.link?.target"
              :title="item.linkTitel"
              :to="useCalcedLink(item)"
              class="main-header__list-link"
            >
              <PflegeIcon
                v-if="item.icon !== '' && props.blok.cta?.length === 0"
                :name="item.icon"
                aria-hidden="true"
                class="main-header__list-link--icon"
                size="l"
              />
              <span v-html="item.linkLabel" />
            </NuxtLink>
          </li>
        </ul>
      </nav>

      <NuxtLink
        v-if="getVisibleIcon !== null"
        :title="getVisibleIcon.linkTitel"
        :to="
          getVisibleIcon.link.linktype !== 'story'
            ? getVisibleIcon.link.cached_url
            : useGetInternalLinkFromUrl(getVisibleIcon.link.cached_url)
        "
        :class="['main-header__list-link', { hidden: handleWindowSize }]"
      >
        <PflegeIcon
          v-if="getVisibleIcon.icon !== null"
          :name="getVisibleIcon.icon"
          aria-hidden="true"
          class="main-header__list-link--icon"
          size="l"
        />

        <span
          v-if="!props.blok.cta?.length > 0"
          :class="[{ hidden: props.blok.cta?.length > 0 }]"
          v-html="getVisibleIcon.linkLabel"
        />
      </NuxtLink>

      <div v-if="props.blok.cta?.length > 0" class="main-header__cta-nav">
        <WPElementButton :blok="props.blok.cta[0]" />
      </div>
    </div>
  </header>
</template>

<script setup>
import { PflegeIcon, PflegeDivider } from '@shared'
import { useWindowSize } from '@vueuse/core'

const props = defineProps({
  blok: {
    type: Object,
    required: true,
    default: () => {
      return {
        isHeaderNavActive: false,
        navLinks: [],
        headerLogo: null,
        headerLogoLink: null,
        cta: null,
        specialNavLinks: []
      }
    }
  }
})

const route = useRoute()
const { width } = useWindowSize()

const result = ref(null)
const isMobileNavbarVisible = ref(false)

const getVisibleIcon = computed(() => {
  if (props.blok.specialNavLinks?.length > 0) {
    result.value = props.blok.specialNavLinks.find(
      (item) => item.showIcon === true && item.icon !== ''
    )
  }
  if (result.value === undefined) {
    return null
  }
  return result.value
})

const handleWindowSize = computed(() => width.value > 811)

watch(
  () => route.fullPath,
  () => {
    isMobileNavbarVisible.value = false
  }
)
</script>

<style lang="scss" scoped>
.main-header {
  background-color: var(--white);
  box-shadow: 0 1rem 1rem 0 #689f9f14;
  position: sticky;
  z-index: 999;
  top: 0;

  &--wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    min-height: 3.5rem;
    max-width: var(--maxwidth);
    margin-inline: auto;
  }

  &__burger-button {
    display: none;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 5;

    &:deep(.button-icon) {
      g > {
        path {
          transform-box: fill-box;
          transform-origin: center;
          transform: translateY(0rem) rotate(0deg);
          transition:
            transform var(--time2) cubic-bezier(0.35, 1.55, 0.65, 1),
            opacity var(--time1) ease-out;

          .flyout-open & {
            &:nth-child(1) {
              transform: translateY(0) rotate(-45deg);
            }

            &:nth-child(2) {
              opacity: 0;
            }

            &:nth-child(3) {
              transform: translateY(-7px) rotate(45deg);
            }
          }
        }
      }
    }
  }

  &__figure {
    margin: 0 auto 0 0.75rem;

    img {
      width: auto;
      height: 1.5rem;

      @media (max-width: 374px) {
        width: calc(100vw - 14rem);
        height: auto;
      }
    }
  }

  &__list {
    display: flex;
    gap: 0.5rem;
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
  }

  &__list-item {
    margin-block-end: 0;
  }

  &__list-link {
    display: flex;
    color: var(--neutral-80);
    font-size: 0.889rem;
    font-weight: 600;
    padding: 0.75rem;
    text-decoration: none;
    transition: color var(--time1) ease-in-out;

    &.router-link-active,
    &:active {
      color: var(--coral-darker);
    }

    &:hover {
      color: var(--coral-light);
    }

    &--icon {
      display: inline-block;
      color: var(--neutral-80);
      margin-right: 0.38888rem;
    }
  }

  &__cta-nav {
    display: flex;
    align-items: center;
    margin-right: 0.75rem;

    &:deep(.button) {
      --size: 2.444rem;
    }

    .icon-wrapper {
      background-color: #008dcc;
    }
  }

  .hidden {
    display: none;
  }

  @media (max-width: 810px) {
    &--wrapper {
      gap: 0;
    }

    &__burger-button {
      padding: 0.5rem 0.889rem;
      display: flex;
      position: absolute;
      left: 0;
      align-items: center;

      .flyout-open & {
        position: fixed;
      }
    }

    &__figure {
      position: relative;
      left: 3.111111rem;
      margin-inline-start: 0;
      transform: translateY(-2px);

      a {
        padding: 0;
      }

      img {
        display: block;
      }
    }

    &__nav {
      --shadow-color: hsla(0, 0%, 0%, 0.75);
      display: block;
      position: fixed;
      opacity: 0;
      top: 0;
      left: -100vw;
      background-color: var(--white);
      height: 100%;
      min-height: 100%;
      overflow: hidden;
      visibility: hidden;
      width: calc(100vw - 2.222rem);
      box-shadow: 0 0 5rem 0.27778rem var(--shadow-color);
      padding: 4rem 3.111rem 0.889rem 0.139rem;
      z-index: 2;
      transition:
        left var(--time2) ease-in-out,
        opacity var(--time1) ease-in-out var(--time1),
        visibility var(--time2) ease-in-out;

      .flyout-open & {
        left: 0;
        opacity: 1;
        visibility: visible;
      }
    }

    &__list {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;

      &--divider {
        margin-left: 0.75rem;

        :deep(hr) {
          border-width: 2px 0 0 0;
        }
      }
    }

    &__list-item {
      width: 100%;
    }

    &__list-link {
      font-size: 1rem;
    }

    &__cta-nav {
      &:deep(.button) {
        --size: 1.5rem;
        padding-inline: 1.333rem;
        min-width: auto;
      }
    }
  }
}
</style>
